// archived due to simulation V2, need to adjust as simulationV2 if needed
// see constants file, need to be inserted in the simulationGenerated object on call

import { getEuroFormat, getPercentFormat } from '../Money'

export default ( simulationGenerated ) => {
    let FP = 0
    let bodyFirstTable = [
        [{ text: simulationGenerated.typeMission === 'TH' ? 'Nombre d\'heures facturées' : (simulationGenerated.typeMission === 'TJM' ? 'Nombre de jours facturés' : 'Coefficient de facturation'), margin: [0, 0, 0, 0] }, { text: simulationGenerated.coefInvoiced, alignment: 'right', noWrap: true }],
        [{ text: simulationGenerated.typeMission === 'TH' ? '* Facturation par heure' : (simulationGenerated.typeMission === 'TJM' ? '* Facturation par jour' : '* Forfait facturé'), margin: [16, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.base), alignment: 'right', noWrap: true }],
        [{ text: '= CA HT facturé', margin: [0, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.CAHT), alignment: 'right', noWrap: true }],
        [{ text: '- Frais de gestion (FG) à ' + getPercentFormat(simulationGenerated.TFG), margin: [16, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.FG), alignment: 'right', noWrap: true }],
        // [{ text: 'Ratio NET A PAYER après impôts /' + simulationGenerated.NBJP +  'J de CA après FG', margin: [16, 0, 0, 0] }, { text: (simulationGenerated.RatNAP2 * 100).toFixed(2) + '%', alignment: 'right', noWrap: true }],
    ]
    if (simulationGenerated.settings.Commercial.simulationProspect.first) {
        bodyFirstTable.push([{ text: '= CA HT après Frais de Gestion', margin: [0, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.CAHTmoinsFG), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.first) {
        bodyFirstTable.push([{ text: '- Coût global du bulletin de salaire', margin: [16, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.COUTG), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.second) {
        bodyFirstTable.push([{ text: '* Charges patronales estimées', margin: [32, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.TotRP), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.third) {
        bodyFirstTable.push([{ text: '* Cotisations salariales estimées', margin: [32, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.CSE), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.fourth) {
        bodyFirstTable.push([{ text: '* Salaire Net estimé', margin: [32, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.SNET), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.fifth) {
        bodyFirstTable.push([{ text: '* Prélèvement à la source (PAS) ' + (simulationGenerated.PASRate * 100) + ' %', margin: [32, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.PAS), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.sixth) {
        bodyFirstTable.push([{ text: '- Frais professionnels remboursables sur justificatifs ou forfaits', margin: [16, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.FP), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.seventh) {
        bodyFirstTable.push([{ text: '- Tickets restaurant sur ' + simulationGenerated.NBTR + ' jours', margin: [16, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.TOTTR), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.eighth) {
        bodyFirstTable.push([{ text: '- Cagnotte constituée (restant de CA HT)', margin: [16, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.CAGNOTTE), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.ninth) {
        bodyFirstTable.push([{ text: 'Net après impôts / + frais professionnel', margin: [0, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.SNETPAS + FP), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.eleventh) {
        bodyFirstTable.push([{ text: 'Ratio NET A PAYER après impôts', margin: [16, 0, 0, 0] }, { text: (simulationGenerated.RatNAP * 100).toFixed(2) + '%', alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.RESERVEFINANCIERE) {
        bodyFirstTable.splice(4, 0, [{ text: '- Réserve financière', margin: [16, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.RESERVEFINANCIERE), alignment: 'right', noWrap: true }],)
    }

    const summaryArray = []
    if (simulationGenerated.settings.Commercial.simulationProspect.twelfth) {
        summaryArray.push([{ text: 'Montant de la facturation', margin: [0, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.CAHT), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.thirteenth) {
        summaryArray.push([{ text: '- Frais de gestion (FG) à ' + getPercentFormat(simulationGenerated.TFG), margin: [0, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.FG), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.fourteenth) {
        summaryArray.push([{ text: '- Coût global du bulletin de salaire', margin: [0, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.COUTG), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.fifteenth) {
        summaryArray.push([{ text: '- Frais professionnels remboursables sur justificatifs ou forfaits', margin: [0, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.FP), alignment: 'right', noWrap: true }])
    }
    if (simulationGenerated.settings.Commercial.simulationProspect.sixteenth) {
        summaryArray.push([{ text: '- Cagnotte constituée (restant de CA HT)', margin: [0, 0, 0, 0] }, { text: getEuroFormat(simulationGenerated.CAGNOTTE), alignment: 'right', noWrap: true }])
    }

    var docDefinition = {
        info: {
            title: 'Simulation',
            author: 'EPPORTAGE',
            subject: 'Simulation',
        },
        content: [
            {
                table: {
                    widths: ['*'],

                    body: [
                        [{ text: 'Votre simulation ' + (simulationGenerated.typeMission !== 'PACKAGE' ? ('sur ' + simulationGenerated.coefPaid + ' ' + (simulationGenerated.typeMission === 'TH' ? 'heures' : 'jours')) : '') + ' (salaire mensuel contractuel)et sous réserve des variations de taux de cotisations', border: [false, false, false, false], decoration: 'underline', alignment: 'center', bold: true }],
                    ]
                },
                layout: {
                    fillColor: '#BDD7EE'
                },
                margin: [0, 0, 0, 16]
            },
            {
                table: {
                    widths: ['*', 105],

                    body: bodyFirstTable
                },
                layout: {
                    fillColor: function (rowIndex) {
                        if (rowIndex < 3) {
                            return '#FFD966'
                        }
                        if (rowIndex < (simulationGenerated.RESERVEFINANCIERE ? 6 : 5)) {
                            return '#C6E0B4'
                        }
                        if (rowIndex < 13) {
                            return '#BDE4F9'
                        }
                        if (rowIndex < 16) {
                            return '#F8CBAD'
                        }
                        return '#FFFFFF';
                    }
                },
                margin: [0, 0, 0, 32]
            },
            summaryArray.length > 0 ? {
                table: {
                    widths: ['*'],

                    body: [
                        [{ text: 'En résumé', border: [false, false, false, false], decoration: 'underline', bold: true, color: '#FFF' }],
                    ]
                },
                layout: {
                    fillColor: '#FA4848'
                },
                margin: [0, 0, 0, 16]
            } : {},
            summaryArray.length > 0 ? {
                table: {
                    widths: ['*', 105],

                    body: summaryArray
                    // [{ text: 'Ratio NET A PAYER / CA HT avec réintégration de 2 jour(s) supp si utilisés avec les dispositifs sociaux**', margin: [0, 0, 0, 0] }, { text: (simulationGenerated.RatNAP2 * 100).toFixed(2) + '%', alignment: 'right', noWrap: true, margin: [0, 6, 0, 0] }]

                },
                margin: [0, 0, 0, 32]
            } : {},
            {
                table: {
                    widths: ['*', 105],

                    body: [
                        [{ text: 'Salaire de base indiqué sur le contrat de travail :', border: [false, false, false, false], bold: true, color: '#f00', margin: [0, 5, 0, 5] }, { text: getEuroFormat(simulationGenerated._SBC), alignment: 'right', noWrap: true, border: [false, false, false, false], bold: true, color: '#f00', margin: [0, 5, 0, 5] }],
                    ],
                },
                layout: {
                    fillColor: '#FFFF00'
                },
                margin: [0, 0, 0, 0]
            },
            { text: 'Le salaire de base + Prime d\'apporteur d\'affaire + Prime conventionnelle de vacances + congés payés = salaire brut', italics: true },
        ],
    }
    return docDefinition
}
